<template>
  <v-container class="px-0 mt-1 mb-8 custom-fix-margin-top">
    <v-row justify="center">
      <v-col
        v-for="(container, index) in containerCardData"
        :key="index"
        align="center"
        class="px-0">
        <CdeCard
          :width="smAndDown ? '170' : '220'"
          :height="smAndDown ? '170' : '200'"
          class="fill-height d-flex flex-column align-center justify-center py-3 px-0">
          <v-card-item>
            <v-card-title class="text-center font-weight-bold">
              {{ container?.size }}
            </v-card-title>
          </v-card-item>

          <v-img
            :src="`https://epd.directus.app/assets/${container?.image}?format=webp&width=140`"
            :srcset="`https://epd.directus.app/assets/${container?.image}?format=webp&width=140 1x, https://epd.directus.app/assets/${container?.image}?format=webp&width=140 2x`"
            class="mb-4"
            max-height="200"
            width="140"
            :alt="container?.image_alt_text || ''"
            contain />

          <div class="text-center">
            {{ container?.dimension }}
          </div>
        </CdeCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { useContainerStore } from '@/stores/container'
import { useDisplay } from 'vuetify'
import type { SizeWrapper } from '~/types/types'

const { smAndDown } = useDisplay()

const containerStore = useContainerStore()

await containerStore.getContainerData()

const props = defineProps<{
  sizeWrapper: SizeWrapper
}>()

const containerCardData = props.sizeWrapper.containers.map(container => {
  return containerStore.getContainerById(container.container_id)
})
</script>

<style scoped lang="scss">
.custom-fix-margin-top {
  margin-top: -100px;
}
</style>
